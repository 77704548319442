import React, { Fragment } from "react";
import { Link, graphql } from "gatsby";

import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import Layout from "@/components/layout";
import Footer from "@/components/footer";
import PageBanner from "@/components/page-banner";
import MenuContextProvider from "@/context/menu-context";
import HeaderTwo from "@/components/header-two";
import SEO from "../components/seo";
import BlogSidebar from "@/components/blog-sidebar";

const BlogPost = ({ data }) => {
  const tags = data.allMarkdownRemark.group;
  const post = data.markdownRemark;
  const tagCount = (post.frontmatter.tags.length - 1);
  const siteTitle = `${data.site.siteMetadata.title} | ${post.frontmatter.title}`;
  const { previous, next } = data;

  const kebabCase = string => string
    .replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/[\s_]+/g, '-')
    .toLowerCase();

  return (
    <Fragment>
      <MenuContextProvider>
        <Layout PageTitle={siteTitle}>
          <SEO title={post.frontmatter.title} description={post.excerpt || post.frontmatter.description} />
          <HeaderTwo />
          <PageBanner title="Blog" />
          <section className="commonSection">
            {/* blog_details */}
            <Container>
              <Row>
                <Col lg={8} sm={8}>
                  <article className="single_blog" itemScope itemType="http://schema.org/Article">
                    <div className="blog_thumb">
                      {/* <img src={blogDetailsImage} alt="" /> */}
                    </div>
                    <div className="blog_headings">
                      <h1>{post.frontmatter.title}</h1>
                    </div>
                    <div className="blog_details">
                      <section
                        dangerouslySetInnerHTML={{ __html: post.html }}
                        itemProp="articleBody"
                      />
                    </div>
                    <div className="blog_tagitems">
                      <span>Tags:</span>
                      {
                        post.frontmatter.tags.map((tag, index) => {
                          if (index === tagCount) {
                            return (
                              <span>
                                <Link to={`/tags/${kebabCase(tag)}`}>
                                  {tag}
                                </Link>
                              </span>
                            )
                          } else {
                            return (
                              <span>
                                <Link to={`/tags/${kebabCase(tag)}`}>
                                  {tag}
                                </Link>,
                              </span>
                            )
                          }
                        })
                      }
                    </div>
                  </article>
                  {/* Flipped due to the way the blogs are sorted */}
                  <div className="meipaly_paginations text-center">
                    {previous && (
                      <Link to={`/blog${previous.fields.slug}`} rel="prev" className="prev">
                        ← {previous.frontmatter.title}
                      </Link>
                    )}
                    {next && (
                      <Link to={`/blog${next.fields.slug}`} rel="next" className="next">
                        {next.frontmatter.title} →
                      </Link>
                    )}
                  </div>
                </Col>
                <Col lg={4} sm={4} className="blog-sidebar">
                  <BlogSidebar data={tags} />
                </Col>
              </Row>
            </Container>
          </section>
          <Footer />
        </Layout>
      </MenuContextProvider>
    </Fragment>
  );
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        date(formatString: "MMMM DD, YYYY")
        description
        tags
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
